/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path="../../../Cider.Framework/Assets/TypeScript/modal-helper.ts"/>

class PurchaseProduct {

    private modalHelper: ModalHelper;
    private form: JQuery;

    constructor(
        private clickTarget: JQuery,
        private modal: JQuery,
        private suppressModal: boolean) {

        this.modalHelper = new ModalHelper(modal);
        this.form = modal.find('form');
    }

    public init(): void {

        this.clickTarget.click(() => {
            this.onClick();
        });

        this.modal.on('click', '[type="submit"]', () => {
            // The form has been submitted, so close the modal.
            // Do a cancel instead of a save because it's possible
            // that the user may be staying on the current page. Since 
            // we're doing a cancel, if they then open the modal a 
            // second time it will have been reverted back to its default 
            // state.
            this.modalHelper.cancel();
        });

        this.modal.on('hidden.bs.modal', () => {
            // If the modal is cancelled it will revert the selection
            // back to its previous value. We need to trigger another
            // change on the promotion choice to reinitialize the
            // add-to-cart shape.
            this.modal.find('select.add-to-cart-promotion-choice').change();
        });
    }

    private onClick(): void {
        if (this.suppressModal) {
            // Note that the submit handler is overridden in cart.ts.
            // This submit event may not submit the form, but may instead
            // make an AJAX call to add the product to the cart
            // asynchronously.
            this.form.submit();
        }
        else {
            this.modalHelper.show();
        }
    }
}